import { Route, Routes } from 'react-router-dom'
import AppLayout from './AppLayout'
import MyPortfolio from '../pages/MyPortfolio'
import About from '../pages/About'
import Project from '../pages/Project'

export default function AppRouter() {
  return (
    <Routes>
        <Route path='/' element={<AppLayout />}>
            <Route index element={<MyPortfolio />} />
            <Route path=':id' element={<Project />} />
            <Route path='about' element={<About />} />
        </Route>
    </Routes>
  )
}
