import './Modal.css'
import CloseIcon from '../assets/close.png'

export default function Modal(props) {

    const {
        isOpen = false,
        handleClose = null,
        title = "Modal Title",
        content = "Modal Content",
        secondaryBtn = "Close",
        primaryBtn = "Save",
        handleDo = null
    } = props;

    return ( isOpen &&
        <div className='DarkBackground' onClick={handleClose}>
            <div id='Modal' onClick={(e) => e.stopPropagation()}>
                <div id='ModalHeader'>
                    {title}
                    <div
                        id='ModalHeaderCloseImg'
                        onClick={handleClose}
                    >
                        <img alt='' src={CloseIcon} />
                    </div>
                </div>
                <div id='ModalContent'>
                    {content}
                </div>
                <div id='ModalFooter'>
                    <div className='SecondaryBtn' onClick={handleClose} style={{marginRight:"1rem"}}>{secondaryBtn}</div>
                    <div className='PrimaryBtn' onClick={handleDo}>{primaryBtn}</div>
                </div>
            </div>
        </div>
    )
}
