import { Link, NavLink } from "react-router-dom";
import './Navbar.css'
import Logo from '../assets/logo.jpg'
import LinkedIn from '../assets/linkedIn.png'
import LinkedInHover from '../assets/linkedInHover.png'
import GitHub from '../assets/gitHub.png'
import GitHubHover from '../assets/gitHubHover.png'
import { useState } from "react";
import HamburgerIcon from '../assets/hamburger.png'
import CloseIcon from '../assets/close.png'

export default function Navbar() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [liIsHovered, setLiIsHovered] = useState(false);
    const [ghIsHovered, setGhIsHovered] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const handleLiMouseEnter = () => {
        setLiIsHovered(true);
    };
    const handleLiMouseLeave = () => {
        setLiIsHovered(false);
    };
    const handleGhMouseEnter = () => {
        setGhIsHovered(true);
    };
    const handleGhMouseLeave = () => {
        setGhIsHovered(false);
    };

    return (
        <nav className="Navbar">
            <div className="NavbarLeft">
                <div className="Logo">
                    <Link to='/'>
                        <img alt="" src={Logo} />
                    </Link>
                </div>

                {/* Mobile Hamburger Menu Button */}
                <button className="HamburgerBtn" onClick={toggleMenu}>
                    <img alt='' src={HamburgerIcon} />
                </button>

                <div className={isMenuOpen ? "NavLinks Active" : "NavLinks"}>
                    <button className={isMenuOpen ? "CloseBtn Active" : "CloseBtn"} onClick={toggleMenu}>
                        <img alt='' src={CloseIcon} />
                    </button>
                    <NavLink 
                        to="/" 
                        className={({ isActive }) => (isActive ? "NavLink Active" : "NavLink")}
                        onClick={()=>setIsMenuOpen(false)}
                    >
                        Home
                    </NavLink>
                    <NavLink 
                        to="/about" 
                        className={({ isActive }) => (isActive ? "NavLink Active" : "NavLink")}
                        onClick={()=>setIsMenuOpen(false)}
                    >
                        About
                    </NavLink>
                </div>
            </div>

            <div className="Socials">
                <a 
                    href={"https://linkedin.com/in/oikonomakis-d"} 
                    target="_blank"
                    rel="noopener noreferrer" // It ensures that the new tab does not have access to your page's context and helps prevent security vulnerabilities.
                    onMouseEnter={handleLiMouseEnter} 
                    onMouseLeave={handleLiMouseLeave}
                >
                    <img 
                        alt='' 
                        src={liIsHovered ? LinkedInHover : LinkedIn}
                    />
                </a>
                <a 
                    href={"https://github.com/dem-cloud"} 
                    target="_blank"
                    rel="noopener noreferrer" // It ensures that the new tab does not have access to your page's context and helps prevent security vulnerabilities.
                    onMouseEnter={handleGhMouseEnter} 
                    onMouseLeave={handleGhMouseLeave}
                >
                    <img 
                        alt='' 
                        src={ghIsHovered ? GitHubHover : GitHub} 
                    />
                </a>
            </div>
        </nav>
    )
}
