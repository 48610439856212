import { useParams } from "react-router-dom"
import './Project.css'
import projects from '../data/projects.json'
import YoutubeEmbed from "../components/YoutubeEmbed"
import { useState } from "react"
import Modal from "../components/Modal"

export default function Project() {
    const {id} = useParams()
    const currentP = projects.filter(p => p.id === parseInt(id || '0', 10))

    const {
        title,
        brandName,
        promotingDesc,
        description,
        imgUrl,
        stack,
        links,
        liveUrl,
        embedId,
        progress
    } = currentP[0]

    const [modalIsOpen, setModalIsOpen] = useState<Boolean>(false)
    const [reqLink, setReqLink] = useState<string>('')

    const handleContact = () => {
        const email = 'demetriuseconomakis@gmail.com';
        const subject = encodeURIComponent('Hello Demetris');

        const req = reqLink === "Figma" ? `${reqLink} design` : `${reqLink} repository`
        const body = encodeURIComponent(`I would like to access your ${req} for «${title}» project!`);

        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };

    return (
        <div className="Project">

            <h2 id="ProjectTitle">{brandName}: {title}</h2>
            <p id="ProjectPromotingDesc">{promotingDesc}</p>
            <div id="ProjectDetailsImgNLinks">
                <div id="ProjectImg">
                    <img alt='' src={imgUrl} />
                </div>
                <div id="ProjectDetails">
                    <div id="ProjectDetailsCols">
                        <div>
                            <h4>Stack</h4>
                            {
                                stack && stack.map((st,index) => {
                                    return <div 
                                                key={index}
                                                className="ProjectDetailsCol"
                                            >
                                                {st}
                                            </div>
                                })
                            }
                        </div>
                        <div>
                            <h4>Links</h4>

                            {links && links.map((link,index) => {
                                const {name,url} = link;
                                return  <div 
                                            key={index}
                                            className="ProjectDetailsCol"
                                            onClick={()=>{
                                                name !== "Figma" && setModalIsOpen(true);
                                                setReqLink(name)
                                            }}
                                        >
                                            { 
                                            name !== "Figma" ? 
                                                name
                                                :
                                                <a 
                                                    href={url} 
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {name}
                                                </a>
                                            }
                                        </div>
                            }) 
                            }
                        </div>
                        <div>
                            <a 
                                id="ProjectLiveView"
                                href={liveUrl} 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Live View
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ProjectDesc">
                <h3>Project Purpose and Goal</h3>
                {description}
            </div>
            { embedId &&
                <div id="ProjectTutorial">
                    <h3>Tutorial</h3>
                        <YoutubeEmbed embedId={embedId} />
                </div>
            }
            <div id="ProjectAppStatus">
                <h3>Application Status</h3>
                <div id="ProjectProgressBar">
                    <div id="ProjectProgressBarStatus">
                        <span>Progress</span>
                        <span id="ProjectProgressBarPercentage">{progress}</span>
                    </div>
                    <div id="ProjectProgressBarFull">
                        <div id="ProjectProgressBarCompleted" style={{width: progress}}></div>
                    </div>
                </div>
                {/* <div id="">
                    <div>
                        <h3>Things need to be done</h3>
                    </div>
                </div> */}
            </div>

            <Modal 
                isOpen = {modalIsOpen}
                handleClose = {() => setModalIsOpen(false)}
                title = "Opps! Access Denied"
                content = {`This ${reqLink === "Figma" ? 'Figma design' : 'Github repository'} is private. Please contact me to give you permission.`}
                primaryBtn = "Contant"
                handleDo = {handleContact}
            />
        </div>
    )
}
