import { useEffect, useMemo, useState } from 'react'
import SkeletonLoader from '../components/SkeletonLoader';
import projects from '../data/projects.json'
import './MyPortfolio.css'
import { Link } from 'react-router-dom';

export default function MyPortfolio() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadedImages, setLoadedImages] = useState([]);
    const [activeCategory, setActiveCategory] = useState('All')

    useEffect(() => {
        const fetchData = async () => {
            // const response = await fetch('/api/data');
            // const result = await response.json();
            const result = projects;
            setData(result);
            setLoading(false);
        };
        
        fetchData();
    }, []);

    const handleImageLoad = (id) => {
        setLoadedImages((prev) => [...prev, id]);
    };

    // Memoized filtered projects based on selected category
    const filteredData = useMemo(() => {
        return activeCategory === 'All'
            ? data
            : data.filter(item => item.platform === activeCategory);
    }, [activeCategory,data]);

    return (
        <div className='MyPortfolio'>
            <div className='Title'>
                <h2>Hi, I'm Demetris</h2>
                <h3>Welcome to my Portfolio!</h3>
            </div>
            <div className='PortfolioCategories'>
                {['All', 'Web Design', 'Mobile Apps'].map(category => (
                    <div
                        key={category}
                        className={activeCategory === category ? 'PortfolioCategoryBtn Active' : 'PortfolioCategoryBtn'}
                        onClick={() => setActiveCategory(category)}
                    >
                        {category}
                    </div>
                ))}
            </div>
            <div className="GridLayout">
                {loading ? (
                    Array.from({ length: projects.length }).map((_, index) => ( // Adjust the number based on how many skeletons you want
                        <div key={index} className="GridItem">
                            <SkeletonLoader />
                        </div>
                    ))
                ) : (
                    filteredData.map(item => {
                        const { id, title, imgUrl, live } = item;

                        return  <div key={id} className="GridItem">
                                    <img
                                        src={imgUrl}
                                        alt={title}
                                        className="LoadedImage"
                                        style={{ display: loadedImages.includes(id) ? 'block' : 'none' }}
                                        onLoad={() => handleImageLoad(id)}
                                        // loading="lazy"
                                    />
                                    {!loadedImages.includes(id) ? 
                                        <SkeletonLoader />
                                        :
                                        <div className={`GridItemContent ${live ? 'SlideOut' : 'Locked'}`}>
                                            <span id='GridItemContentTitle'>{live ? title : 'Coming Soon . . .'}</span>
                                            {live && <Link to={`${id}`} id='GridItemContentBtn'>View Details</Link>}
                                        </div>
                                    }
                                </div>
                    })
                )}
            </div>
        </div>
    );
}
