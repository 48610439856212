import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

export default function AppLayout() {
    return (
        <>
            <Navbar />
            <div style={{padding: "4rem 5rem"}}>
            <Outlet />
            </div>
        </>
    )
}
