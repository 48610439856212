import './About.css'
import Me from '../assets/me.png'
import GH from '../assets/gitHubHover.png'
import LI from '../assets/linkedInHover.png'
import skills from '../data/skills.json'
import { useState } from 'react'

export default function About() {

    const [imgLoaded, setImgLoaded] = useState<Boolean>(false);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/files/cv.pdf`;
        link.download = 'demetris-economakis-cv.pdf';
        link.click();
    };

    const handleEmailClick = () => {
        const email = 'demetriuseconomakis@gmail.com';
        const subject = encodeURIComponent('Hello Demetris');
        const body = encodeURIComponent('I would like to hire you.');

        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    };

    return (
        <div className="About">
            {/* <div className="Title">
                <h3>About</h3>
            </div> */}
            <div id="Info">
                <div id="InfoText">
                    <div id="InfoName">Demetris Economakis</div>
                    <div id="InfoSpeciality">Full Stack Developer</div>
                    <p id='InfoDesc'>
                    A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks!
                    </p>
                    <div id="InfoSocial">
                        <a 
                            href={"https://linkedin.com/in/oikonomakis-d"} 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt='' src={LI} />
                        </a>
                        <a 
                            href={"https://github.com/dem-cloud"} 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt='' src={GH} />
                        </a>
                    </div>
                    <div>
                        <button 
                            id='InfoHireMeBtn'
                            onClick={handleEmailClick}
                        >
                            HIRE ME
                        </button>
                        <button 
                            id='InfoGetCVBtn'
                            onClick={handleDownload}
                        >
                            GET CV
                        </button>
                    </div>
                </div>
                <div id="InfoMe" >
                    <div 
                        className={ `background-overlay ${imgLoaded ? 'hide' : ''}` }
                    >
                    </div>
                    <img 
                        id="InfoMeImg" 
                        className={ imgLoaded ? 'loaded' : '' } 
                        alt='' 
                        src={Me} 
                        loading='lazy' 
                        onLoad={()=> setImgLoaded(true)}
                    />
                </div>
            </div>
            <div className='SectionLine'></div>
            <div id="Skills">
                <h2>Skills and Tools</h2>
                <p>The skills, tools and technologies I use to brind your products to life:</p>
                <div id='SkillsLayout'>
                    {
                    skills.map(skill => {
                        const {id, imgUrl, name} = skill;
                        return <div 
                                    key={id}
                                    className='Skill'
                                >
                                    <img alt='' src={imgUrl} />
                                    <span>{name}</span>
                                </div>
                    })
                    }
                </div>
            </div>
        </div>
    )
}
